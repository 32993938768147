<template>
  <b-row>
    <b-col
      cols="12"
      md="8"
    >
      <b-card>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <user-name />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <user-title />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <user-email />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <user-phone />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <user-type />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <user-status />
          </b-col>
        </b-row>
        <user-password />
      </b-card>
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <user-avatar />
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import UserName from '@/views/Admin/Users/elements/UserName.vue'
import UserTitle from '@/views/Admin/Users/elements/UserTitle.vue'
import UserEmail from '@/views/Admin/Users/elements/UserEmail.vue'
import UserPhone from '@/views/Admin/Users/elements/UserPhone.vue'
import UserPassword from '@/views/Admin/Users/elements/UserPassword.vue'
import UserType from '@/views/Admin/Users/elements/UserType.vue'
import UserStatus from '@/views/Admin/Users/elements/UserStatus.vue'
import UserAvatar from '@/views/Admin/Users/elements/UserAvatar.vue'

export default {
  name: 'UserForm',
  components: {
    BCard,
    BRow,
    BCol,
    UserName,
    UserTitle,
    UserEmail,
    UserPhone,
    UserPassword,
    UserType,
    UserStatus,
    UserAvatar,
  },
}
</script>
