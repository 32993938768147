<template>
  <b-card>
    <div class="w-75 mx-auto">
      <b-img
        v-if="dataItem.image"
        :src="baseURL + '/media/users/' + dataItem.image"
        center
        fluid-grow
        class="rounded-circle"
      />
    </div>
    <div class="w-75 mx-auto mt-2">
      <b-button
        variant="success"
        block
        pill
        @click="setModal('users')"
      >
        <FeatherIcon icon="UploadIcon" />
        Fotoğraf Yükle
      </b-button>
    </div>
    <cropper-image
      v-if="cropperSettings.type"
      :modal-status="cropperSettings.modalStatus"
      :close-modal="closeModal"
      :file-name="cropperSettings.fileName"
      :folder="cropperSettings.folder"
      :ratio="cropperSettings.ratio"
      @uploadImage="setUploadImage"
    />
  </b-card>
</template>
<script>
import { BCard, BImg, BButton } from 'bootstrap-vue'
import CropperImage from '@/layouts/components/common/CropperImage.vue'

export default {
  name: 'UserAvatar',
  components: {
    CropperImage,
    BCard,
    BImg,
    BButton,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      images: [],
      cropperSettings: {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['users/dataItem']
    },
  },
  methods: {
    setModal(type) {
      if (type === 'users') {
        this.cropperSettings = {
          modalStatus: true,
          fileName: 'user-avatar.jpg',
          folder: 'users',
          ratio: 1200 / 1200,
          type: 'users',
        }
      }
    },
    closeModal() {
      this.cropperSettings = {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      }
    },
    setUploadImage(item) {
      if (this.cropperSettings.type === 'users') {
        this.dataItem.image = item.image
      }
      this.cropperSettings.modalStatus = false
    },
  },
}
</script>
